<template>
  <img />
</template>

<script>
import { backendUrl } from "@/utils/studio7ApiService";

export default {
  name: "ImageAttachment",
  props: {
    src: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      hasRefetch: false
    };
  },
  mounted() {
    const img = this.$el;
    img.addEventListener("load", this.imageValidator);
    img.addEventListener("error", this.imageValidator);

    // We want to load the src after all event listeners has been registered
    img.src = this.src;
  },
  methods: {
    /**
     * Validate the content of an image tag
     * @param { HTMLImageElement } img The image element to validate
     * @returns { boolean } Return true if the content of the image is valid. Otherwise, false will be returned
     */
    imageIsValid(img) {
      const isComplete = img.complete;
      const hasWidth = img.naturalWidth > 0;
      const hasHeight = img.naturalHeight > 0;

      return isComplete && hasWidth && hasHeight;
    },

    /**
     * Validate the content of an image tag after an event
     * @param { Event } event
     */
    imageValidator(event) {
      const img = event.target;
      if (!this.imageIsValid(img)) {
        this.retryFetchImage(img);
      }
    },

    /**
     * Retry to fetch the image content using studio backend
     * @param { HTMLImageElement } img The image element to validate
     */
    retryFetchImage(img) {
      if (!this.hasRefetch) {
        const url = new URL(backendUrl);
        url.pathname += "/fetch-remote";
        url.searchParams.append("requestUrl", img.src);
        url.searchParams.append("vfId", location.pathname.split("/").pop());
        img.src = url.href;

        // Disable retry fetch. No other way to fetch the remote resource
        this.hasRefetch = true;
      }
    }
  }
};
</script>
